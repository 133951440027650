@import 'variables';

.card-2 {
  position: relative;
  height: auto;

  .card-body {
    position: absolute;
    top: auto;
    bottom: 0;
    max-width: 85%;
    margin: 0 0 25px;
    padding: 12px 12px 12px 0;
    background-color: #fff;
    border: 0;
  }
}

.card-3 {
  position: relative;
  height: auto;
  .podcast-icon{
    display: block;
    width: 24px;
    margin: 0 auto 10px;
  }

  .card-body {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px 15px;
    border: 0;
    text-align: center;
  }

  .text-title {
    color: white;
  }

  .text-category {
    background: #ea1717;
    color: #fff;
    display: inline-block;
    padding: 0.15rem 0.35rem;
  }

  .ratio::after {
    content: "";
    height: 300px;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    background: linear-gradient(0deg, rgba(0, 0, 0, .6), rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0));
  }
}
